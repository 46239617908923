























































import { Component, Vue } from 'vue-property-decorator';
import { getPublishFormTree } from '@/api/form-design/FormApi';
import FormDataManager from '@/components/form-data-manager/form-data-manager.vue';

@Component({
  name: 'FormDataList',
  components: { FormDataManager }
})
export default class FormDataList extends Vue {
  // 应用树prop
  formTreeProp: any = {
    id: 'id',
    label: 'text',
    children: 'children'
  };
  // 应用树列表
  formTreeData: any[] = [];
  // 应用树过滤文本框内容
  filterText: string = '';
  // 左侧应用类型树当前选中的表单id
  curFormCode: string = '';
  curForm: any = {}; // 当前选中的表单

  /**
   * created
   */
  created() {
    // 获取应用类型列表
    this.getFormTreeData();
  }

  /**
   * 获取左侧应用树列表
   */
  getFormTreeData() {
    let params = {
      name: this.filterText
    };
    getPublishFormTree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        this.formTreeData = [{ id: '-1', text: this.$t('lang_top_node'), children: res.data }];
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    if (data) {
      if (data.id === '-1') {
        return;
      }
      this.curFormCode = data.code;
      this.curForm = data;
    }
  }
}
