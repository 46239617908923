var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent" },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c("div", { staticClass: "main-left-tree-header" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("lang_app_management")))]),
              ]),
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-input", {
                    staticClass: "main-left-tree-filter mgt-10",
                    attrs: {
                      placeholder: _vm.$t(
                        "lang_enter_the_keyword_for_iltering"
                      ),
                      clearable: "",
                    },
                    on: { clear: _vm.getFormTreeData },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getFormTreeData($event)
                      },
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("el-tree", {
                    ref: "formTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.formTreeData,
                      props: _vm.formTreeProp,
                      "default-expand-all": "",
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [_c("span", [_vm._v(_vm._s(data.text))])]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "right" },
            [_c("form-data-manager", { attrs: { form: _vm.curForm } })],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }